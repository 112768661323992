<!--支付页-->
<template>
    <div>
        <div class="price-wrapper">
            <span>￥</span>
            <span>{{moneyFen2Yuan(money)}}</span>
        </div>
        <div class="pay-wrapper">
            <!--纯正微信支付-->
            <div class="pay-item" :class="[currentItems == 'weixin' ? 'item-active' : '']" @click="select('weixin')"
                 v-if="showWeiXin == 1 && showWeiXinApp == 0">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-微信.png">
                <p>微信支付</p>
            </div>
            <!--纯正的微信小程序支付-->
            <div class="pay-item" :class="[currentItems == 'weixinApp' ? 'item-active' : '']"
                 @click="select('weixinApp')" v-if="showWeiXinApp == 1">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-微信.png">
                <p>微信小程序支付</p>
            </div>
            <!--这边伪装了建行支付，如果要改回去，把这段注释掉然后打开纯正微信支付的代码，api init初始化回调里面that.currentItems = 'ccbwx'改成that.currentItems = 'weixin'即可-->
            <div class="pay-item" :class="[currentItems == 'ccbwx' ? 'item-active' : '']" @click="select('ccbwx')" v-if="showCcbWX == 1">
                <!--                            <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-微信.png">-->
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-建行.png">
                <!--                            <p>微信支付</p>-->
                <p>建行支付</p>
            </div>
            <!--建行龙支付-->
            <!--            <div class="pay-item" :class="[currentItems == 'ccbpay' ? 'item-active' : '']" @click="select('ccbpay')"-->
            <!--                 v-if="showCcbPay == 1 && showWeiXinApp == 0">-->
            <!--                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-建行.png">-->
            <!--                <p>建行龙支付</p>-->
            <!--            </div>-->
            <!--          h5  建行数字人民币-->
            <div class="pay-item" :class="[currentItems == 'panda' ? 'item-active' : '']" @click="select('panda')"
                 v-if="shoPanda == 1 && showWeiXinApp == 0">
                <!--                https://seetop-1257860468.cos.ap-shanghai.myqcloud.com/vue/img/payment-shuzirenminbi.jpg-->
                <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/payment-shuzirenminbi.png">
                <p>数字人民币</p>
            </div>
            <!--            建行支付-->
            <!--            <div class="pay-item" :class="[currentItems == 'ccbwx' ? 'item-active' : '']" @click="select('ccbwx')" v-if="showCcbWX == 1">-->
            <!--                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-建行.png">-->
            <!--                <p>建行支付</p>-->
            <!--            </div>-->
            <div class="pay-item" :class="[currentItems == 'unionpay' ? 'item-active' : '']" @click="select('unionpay')"
                 v-if="showUnionPay == 1">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon_ysf.png">
                <p>云闪付</p>
            </div>
            <!--            <div class="pay-item" :class="[currentItems == 'spd' ? 'item-active' : '']" @click="select('spd')">-->
            <!--                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon_pflogo.png">-->
            <!--                <p>浦发支付</p>-->
            <!--            </div>-->
            <!--微信小程序数币支付-->
            <div class="pay-item" :class="[currentItems == 'pandamp' ? 'item-active' : '']"
                 @click="select('pandamp')" v-if="showWeiXinApp == 1 && showPandaMp == 1">
                <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/payment-shuzirenminbi.png">
                <p>数币支付</p>
            </div>
            <div class="pay-item" :class="[currentItems == 'bytedance' ? 'item-active' : '']"
                 @click="select('bytedance')" v-if="showByteDance == 1">
                <img src="https://seetop-1257860468.file.myqcloud.com//vue/img/字节收银台.png">
                <p>抖音收银台</p>
            </div>
            <!--            邮储数币 h5都显示-->
            <div class="pay-item" :class="[currentItems == 'psbc' ? 'item-active' : '']" @click="select('psbc')"
                 v-if="showWeiXinApp == 0 && showPsbc == 1">
                <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/payment-shuzirenminbi.png">
<!--                <p>数字人民币（邮储）1111</p>-->
<!--                <p>每周满减</p>-->
                <div>
                    <p>数字人民币（邮储）</p>
<!--                    <p v-if="['星期五','星期六'].indexOf(currentWeek) >= 0" style="font-size: 0.8rem;color: #c02020;margin-top: 0.5rem;">每周五、六、日满20元享受随机立减5-10元</p>-->
                </div>
            </div>
        </div>
        <float-nav v-if="showWeiXinApp == '1'"></float-nav>
        <div style="width: 100%;height: 6rem;"></div>
        <div class="footer-wrapper">
            <div class="pay-button" @click="pay">确认支付</div>
        </div>
    </div>
</template>

<script>
  import { globalConfig, UnionPayJsdk, WXJsdk } from '../../utils/mixin'
  import { moneyFen2Yuan, getCurrentWeek } from '../../utils/tools'
  import { getLocalStorage } from '../../utils/localStorage'
  import FloatNav from '../../components/common/FloatNav'
  import wx from 'weixin-js-sdk'
  import { tt } from '../../utils/bytedance-jssdk-1.0.2'
  // const tt = require('../../utils/bytedance-jssdk-1.0.2')

  export default {
    name: 'Payment',
    components: { FloatNav },
    mixins: [globalConfig, WXJsdk, UnionPayJsdk],
    data () {
      return {
        currentItems: '',
        money: 0,
        orderId: '',
        showWeiXin: 0,
        showCcbWX: 0,
        shoPanda: 0,
        showPsbc: 0,
        showCcbPay: 0,
        showUnionPay: 0,
        showSpd: 0,
        showWeiXinApp: 0,
        showByteDance: 0,
        openid: '',
        showPandaMp: 0,
        currentWeek: ''
      }
    },
    methods: {
      moneyFen2Yuan,
      // 选择支付方式
      select: function (e) {
        this.currentItems = e
      },
      // 初始化，配置可选支付方式
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/init/payment', {
          orderID: this.orderId
        }).then((response) => {
          const data = response.data
          if (data.status === 1) {
            that.showWeiXin = data.tools.weixin
            that.showCcbWX = data.tools.ccbwx
            that.showUnionPay = data.tools.unionpay
            that.showCcbPay = data.tools.ccbpay
            that.shoPanda = data.tools.pandapay
            that.showByteDance = data.tools.bytedance
            that.showPandaMp = data.tools.pandamp
            that.showPsbc = data.tools.psbc
            that.money = data.info.total_fee
            that.openid = data.info.openid // 跳转小程序支付需要用到
            that.defaultItemActive()
          } else {
            this.$toast({
              message: data.msg,
              onClose: function () {
                that.$router.push('/home')
              }
            })
          }
        })
      },
      // 支付
      pay: function () {
        const that = this
        const srcChannel = getLocalStorage('srcChannel')
        // 小程序数币支付截断（数币）
        if (that.currentItems == 'pandamp') {
          that.pandampPy(that.orderId, srcChannel)
          return
        }
        //  如果在小程序，并且是建行聚合支付
        if ((that.showWeiXinApp == 1) && (that.currentItems == 'ccbwx')) {
          that.ccbMpPay(that.orderId, srcChannel)
          return
        }
        // 微信小程序截断：在小程序打开，并且不属于邮储，因为邮储要跳转
        if ((that.showWeiXinApp == 1) && (['psbc', 'ccbwx'].indexOf(that.currentItems) === -1)) {
          that.wxAppPay(that.orderId, srcChannel)
          return
        }
        this.axios.post(this.apiUrl + 'mall/pay/pay', {
          orderID: that.orderId,
          type: that.currentItems,
          channel: srcChannel,
          src_channel: getLocalStorage('urlChannel'),
          stationId: getLocalStorage('stationId')
        }).then((response) => {
          const data = response.data
          console.log(data)
          if (data.status === 1) {
            switch (that.currentItems) {
              case 'weixin':
                that.callPay(
                  data.res.jsApiParameters.appId,
                  data.res.jsApiParameters.timeStamp,
                  data.res.jsApiParameters.nonceStr,
                  data.res.jsApiParameters.package,
                  data.res.jsApiParameters.signType,
                  data.res.jsApiParameters.paySign,
                  that.webUrl + '#/user'
                )
                break
              case 'ccbwx':
                that.callPay(
                  data.res.appId,
                  data.res.timeStamp,
                  data.res.nonceStr,
                  data.res.package,
                  data.res.signType,
                  data.res.paySign,
                  that.webUrl + '#/user'
                )
                break
              case 'unionpay':
                // alert(data.res.tn)
                that.callUnionPay(data.res.tn)
                break
              case 'ccbpay':
                window.location.href = 'https://club.seetop.net.cn/web/payment2_cbb_jump.html?orderID=' + that.orderId + '&total_fee=' + that.money + '&url=' + data.res
                break
              case 'panda':
                window.location.href = data.res
                break
              case 'psbc':
                window.location.href = data.res
                break
              case 'bytedance':
                // console.log('跳转啊')
                // console.log(tt)
                // console.log(data.res)
                tt.miniProgram.navigateTo({
                  url: '/pages/pay/index?order_id=' + data.res.order_id + '&order_token=' + data.res.order_token,
                  success (res) {
                    console.log('跳转成功', res)
                  },
                  fail (err) {
                    console.log('navigateTo调用失败', err)
                    that.$toast(err)
                  }
                })
                break
              default:
                break
            }
          } else {
            that.$toast(data.msg)
          }
        })
      },
      // 页面初始化默然选中的支付方式
      defaultItemActive: function () {
        if (this.showWeiXinApp === 1) {
          this.currentItems = 'weixinApp'
        } else if (this.showWeiXin === 1) {
          this.currentItems = 'weixin'
        } else if (this.showCcbWX === 1) {
          this.currentItems = 'ccbwx'
        } else if (this.showUnionPay === 1) {
          this.currentItems = 'unionpay'
        } else if (this.showCcbPay) {
          this.currentItems = 'ccbpay'
        } else if (this.showByteDance) {
          this.currentItems = 'bytedance'
        }
      },
      // 跳转到微信数币支付，2022.04.04添加，一个新的方法来处理跳转
      pandampPy: function (orderId, srcChannel) {
        const that = this
        wx.miniProgram.navigateTo({
          url: '/pages/pandamp/pandamp?orderID=' + orderId + '&channel=' + srcChannel + '&src_channel=' + srcChannel + '&openid=' + that.openid
        })
      },
      // 跳转到微信小程序支付
      wxAppPay: function (orderId, srcChannel) {
        const that = this
        wx.miniProgram.navigateTo({
          url: '/pages/payment/payment?orderID=' + orderId + '&channel=' + srcChannel + '&src_channel=' + srcChannel + '&openid=' + that.openid
        })
      },
      // 跳转到建行小程序支付
      ccbMpPay: function (orderId, srcChannel) {
        const that = this
        wx.miniProgram.navigateTo({
          url: '/pages/ccbmp/ccbmp?orderID=' + orderId + '&channel=' + srcChannel + '&src_channel=' + srcChannel + '&openid=' + that.openid
        })
      }
    },
    mounted () {
      this.orderId = this.$route.params.orderId
      // 判断到小程序环境，支付的时候跳转去相应页面
      const that = this
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          that.showWeiXinApp = 1
        }
      })
      // 判断云闪付
      const currentBrowser = getLocalStorage('currentBrowser')
      if (currentBrowser === 'unionpay') {
        that.localStorageUnionPayConfig()
      }
      // 判断是不是在字节小程序
      // const isTTWebView = window.navigator.userAgent.toLowerCase().includes('toutiaomicroapp')
      // if (isTTWebView === true) {
      //   that.showByteDance = 1
      // }
      this.init()
      console.log(tt)
      this.currentWeek = getCurrentWeek()
      console.log('当前星期', this.currentWeek)
    }
  }
</script>

<style scoped>
    .price-wrapper {
        background-image: linear-gradient(to right, #333, #444);
        padding: 3rem 0;
        text-align: center;
        color: #ffffff;
        border-bottom: 0.5rem solid #FFF177;
        margin-bottom: 1rem;
    }

    .price-wrapper > span:first-child {
        font-size: 1rem;
    }

    .price-wrapper > span:nth-child(2) {
        font-size: 3rem;
    }

    .pay-button {
        width: fit-content;
        color: #ffffff;
        margin: 1rem auto;
        background-image: linear-gradient(to right, #fd3b3b, #fc6446);
        box-shadow: 1px 2px 2px #cecece;
        padding: 1rem 6rem;
        border-radius: 10rem;
    }

    .footer-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #ffffff;
        box-shadow: 2px 0 6px #999;
    }

    .pay-item {
        width: 90%;
        height: 5rem;
        display: flex;
        flex-flow: row;
        align-items: center;
        box-shadow: 0 0 0.4rem #999999;
        border-radius: 0.5rem;
        background-color: #ffffff;
        padding: 0.5rem 1rem;
        margin: 0.8rem auto 0;
        box-sizing: border-box;
    }

    .pay-item img {
        /*width: 4rem;*/
        max-width: 4rem;
        max-height: 4rem;
        /*height: 4rem;*/
    }

    .pay-item p {
        margin-left: 2rem;
        font-size: 1.2rem;
    }

    .item-active {
        width: 94%;
        height: 5.5rem;
        border: 0.1rem solid #fa1f1f;
        box-shadow: 0 0 0.4rem #fa1f1f;
    }
</style>
